import Image from "next/image";
import { classNames } from "../../utils/formatters";
import Link from "next/link";
import { getImageVariant, ImageVariant } from "../../utils/image";

interface HeroGaleryInterface {
  vehicle: {
    pictures: string[];
    thumbs: string[];
    brand: string;
    model: string;
    year: number;
    price: string;
    slug: string;
    listingTier: string;
    financedByCrediQ?: boolean;
  };
  index: number;
  financedByCrediQ: boolean;
}

export default function HeroGallery({ vehicle, index }: HeroGaleryInterface) {
  const {
    pictures,
    thumbs,
    brand,
    model,
    year,
    price,
    slug,
    listingTier,
    financedByCrediQ,
  } = vehicle;
  const showSomit = false; //index === 0;
  return (
    <Link href={`/autosusados/${slug}`}>
      <div className="relative w-full md:min-h-[400px] grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-1 grid-rows-3 sm:grid-rows-1 lg:px-2">
        {/* first div */}
        <div className="relative col-span-2 row-span-2">
          {!showSomit && (
            <div className="absolute top-2 left-2 z-10 flex items-center gap-2">
              <h1 className="bg-gray-900 opacity-85 shadow-md uppercase text-white py-0.5 px-2 rounded-md text-sm sm:text-lg font-medium ">
                {`${brand} ${model} ${year}`}
              </h1>
            </div>
          )}
          {financedByCrediQ ? (
            <div className="absolute bottom-2 right-2 z-10 flex items-center gap-2 bg-white opacity-85 shadow-md py-0.5 px-2 rounded-md">
              <h1 className=" text-gray-700  ">
                <span className="font-bold">{price} </span>
                Financiable con
              </h1>
              <div className="relative">
                <Image
                  src="/partners/logo_crediq.png"
                  alt="CrediQ Logo"
                  height={26}
                  width={90}
                />
              </div>
            </div>
          ) : (
            <div className="absolute bottom-2 right-2 z-10 flex items-center gap-2">
              <h1 className=" bg-gray-900 opacity-85 shadow-md text-white py-0.5 px-2 rounded-md text-sm sm:text-lg ">
                {price}
              </h1>
            </div>
          )}
          {/* {!showSomit && (
            <div className="absolute bottom-2 right-2 z-10 flex items-center gap-2">
              <h1 className=" bg-gray-900 opacity-85 shadow-md text-white py-0.5 px-2 rounded-md text-sm sm:text-lg ">
                {price}
              </h1>
            </div>
          )} */}
          <Image
            alt={`Foto de ${brand} ${model} ${year}`}
            src={
              showSomit
                ? "/partners/somit-desktop-actual.png"
                : getImageVariant(pictures[0], ImageVariant.CAR_MEDIUM)
            }
            fill
            className="w-full h-full object-center lg:rounded-l-md object-cover block align-middle"
            sizes="(max-width: 640px) 100vw, 75vw"
            priority={index === 0}
            loading={index === 0 ? "eager" : "lazy"}
          />
        </div>

        {/* second div  */}
        <div className="relative md:min-h-[400px] grid gap-1 h-full grid-cols-2 sm:grid-cols-1 lg:grid-cols-2">
          {/* small thumbs */}

          {[...thumbs, thumbs[0]]
            .filter((_, idx) => idx > 0)
            .map((thumbLink, idx) => {
              if (idx > 3) return;
              return (
                <div
                  className={classNames(
                    "relative aspect-w-4 aspect-h-3 w-full h-full",
                    idx === 2 || idx === 3 ? "hidden lg:block" : ""
                  )}
                  key={`${idx}-slider-thumb`}
                >
                  <Image
                    alt={`Foto de ${brand} ${model} ${year}`}
                    src={thumbLink}
                    fill
                    sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
                    className={classNames(
                      "w-full h-full object-center object-cover",
                      idx === 1 ? "lg:rounded-tr-md" : "",
                      idx === 3 ? "lg:rounded-br-md" : ""
                    )}
                    priority={idx === 0}
                    loading={idx === 0 ? "eager" : "lazy"}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Link>
  );
}
