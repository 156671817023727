export enum ImageVariant {
  CAR_MEDIUM = "carmedium",
  CAR_THUMBNAIL = "carthumbnail",
  CAR_GALLERY = "cargallery",
  SHARE_SQUARE = "sharesquare",
  SHARE_RECTANGULAR = "sharerectangular",
}

export function getImageVariant(
  url: string,
  variant: string,
  replace?: string
) {
  if (!url) return "";
  if (replace) {
    return url.replace(replace, `/${variant}`);
  }
  return url.substring(0, url.lastIndexOf("/")) + `/${variant}`;
}

type ImageVariants = {
  pictures: string[];
  thumbs: string[];
};

export function getImageVariants(pictures2: string[] = []): ImageVariants {
  return {
    pictures: pictures2.map((url) =>
      getImageVariant(url, ImageVariant.CAR_GALLERY, "/public")
    ),
    thumbs: pictures2.map((url) =>
      getImageVariant(url, ImageVariant.CAR_THUMBNAIL, "/public")
    ),
  };
}
